<template>
    <div>
        <!-- <h1>正在开发中</h1> -->
        <v-progress-linear :indeterminate="true"></v-progress-linear>
    </div>
</template>

<script setup lang="ts">
    definePageMeta({
        layout: 'panel',
    })
    const website = useWebsiteStore()
    website.setTitle('仪表盘')
</script>
